<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card
                        v-if="url"
                        flat
                    >
                        <v-card-text>
                            <v-img
                                contain
                                :src="url"
                                lazy-src="@/assets/placeholder-png-image.png"
                                transition="scale-transition"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="end"
                                        justify="center"
                                    >
                                        <v-progress-linear
                                            color="#a94442"
                                            indeterminate
                                            rounded
                                            height="6"
                                        ></v-progress-linear>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="cyan" 
                        text
                        depressed
                        @click="onSavePicture"
                    >
                        {{$t("Загрузить")}}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { httpAPI } from "@/api/httpAPI";
export default {
    name: "CommentPicturePreview",
    data () {
        return {
            title: "Превью_изображения",
            visible: false,
            url: null,
            item: null
        }
    },
    methods: {
        async open(url, item)
        {
            this.url = url;
            this.item = item;
            this.visible = true;
            
        },
        cancel() {
            this.visible = false;
            this.url = null;
            this.item = null;
        },
        async onSavePicture(){
            if (process.env.NODE_ENV === "production") {
                // этот механизм будет работать если хост VUE app и хост API одинаковый
                const link = document.createElement('a');
                link.href = this.url;
                link.download = this.item.Name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            // для разработки
            else {                
                let response = await httpAPI({ url: this.url.replace('production', 'development'), method: 'GET', responseType: 'blob' });

                if (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.item.Name);
                    document.body.appendChild(link);
                    link.click();
                }
            }
        }
    }
}
</script>